.help-modal {
  .help-modal-carousel {
    .slick-list {
      .slick-track {
        margin-bottom: 36px;
      }
      .help-body {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        max-height: 600px;
        .help-media {
          align-self: center;
          .help-media-image {
            max-height: 500px;
            max-width: 1000px;
          }
          .help-media-video {
            max-height: 500px;
            max-width: 1000px;
          }
        }
      }
    }
    .slick-dots {
      bottom: 0;
      li {
        padding: 5px;
        border-radius: 5px;
        background: var(--color-neutral-20);
        button {
          background: var(--color-neutral-100);
        }
        &.slick-active {
          background: transparent;
          button {
            background: var(--primary-color-50);
          }
        }
      }
    }
  }
}

.project-results-insights {
  .accionable-collapse {
    background-color: var(--color-neutral-0);
    border-radius: 0px 16px 16px 0px;
    .ant-collapse-item {
      border-left: 4px solid var(--color-neutral-100);
      min-height: 93px;
      &.ant-collapse-item-active {
        border-left: 4px solid var(--primary-color-50);
      }
      .panel-header-title {
        font-size: 16px;
        font-weight: var(--font-weight-medium);
      }
      .panel-header-subtitle {
        font-size: 14px;
        color: var(--color-neutral-80);
      }
      .panel-header-quantity {
        font-size: 16px;
        color: var(--color-neutral-80);
      }
    }
  }
}

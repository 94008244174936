:root {
  --color-neutral-0: #ffffff;
  // --color-neutral-10: #fafafa;
  --color-neutral-20: #f6f6f6;
  --color-neutral-30: #ededed;
  --color-neutral-40: #d9d9d9;
  --color-neutral-50: #b2b2b2;
  // --color-neutral-60: #8c8c8c;
  // --color-neutral-70 : #595959;
  --color-neutral-80: #444444;
  --color-neutral-90: #2e2e2e;
  --color-neutral-100: #000000;

  --primary-color-40: #ff75f4;
  --primary-color-50: #ed20eb;
  --primary-color-60: #d435d1;

  --color-error: #f5222d;
  --color-success: #52c41a;
  --color-warning: #fadb14;

  --font-primary: 'Poppins', sans-serif;
  $weights: (
    // thin: 100,
    // extra-light: 200,
    // light: 300,
    regular: 400,
    medium: 500,
    semi-bold: 600,
    bold: 700,
    // extra-bold: 800
  );
  @each $name, $weight in $weights {
    --font-weight-#{$name}: #{$weight};
  }
}

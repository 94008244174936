.pready-login {
  height: 100vh;
  .pready-login-sider {
    color: var(--color-neutral-0);
    background-color: var(--color-neutral-100);
    padding: 67px 56px;
    .login-info-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      .login-statistics {
        margin-top: 15%;
        .login-statistics-value {
          font-size: 100px;
          font-weight: var(--font-weight-semi-bold);
        }
        .login-statistics-content {
          font-size: 27px;
        }
      }
      .login-review-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 220px;
        max-width: 600px;
        color: var(--color-neutral-0);
        background-color: var(--color-neutral-90);
        border-radius: 16px;
        padding: 30px 24px 22px;
        font-size: 16px;
        .login-review-content {
          letter-spacing: 0.02em;
          line-height: 28px;
        }
        .login-review-author-container {
          .login-review-author-img {
            width: 75px;
            height: 75px;
            background-color: var(--color-neutral-40);
            border-radius: 16px;
          }
          .login-review-author {
            font-weight: var(--font-weight-bold);
          }
        }
      }
    }
  }
  .pready-login-form-container {
    padding: 0 65px;
    margin-top: 12%;
    background-color: var(--light-color);
    .ant-space {
      width: 100%;
    }
    .pready-login-link {
      color: var(--color-neutral-100);
    }
    .pready-login-forgot-password {
      width: 100%;
      display: inline-block;
      text-align: end;
      margin-bottom: 40px;
    }
    .pready-login-form-footer {
      width: 100%;
      display: inline-block;
      text-align: center;
    }
  }
}

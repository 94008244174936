.pready-table {
  width: 100%;
  .ant-table.ant-table-small {
    background-color: transparent;
    table {
      border-spacing: 0;
    }
    .ant-checkbox-wrapper {
      border-spacing: 0;
    }
    .ant-table-cell {
      color: var(--color-neutral-100);
    }
    .ant-table-thead {
      .ant-table-cell {
        color: var(--color-neutral-0);
        background: var(--color-neutral-100);
        font-weight: var(--font-weight-medium);
        font-size: 13px;
        &:hover {
          background: var(--color-neutral-100);
        }
        .ant-table-column-sorter {
          color: var(--color-neutral-80);
        }
      }
    }
    .ant-table-tbody {
      .ant-table-row {
        background: var(--color-neutral-0);
        &:nth-child(even) {
          background-color: var(--color-neutral-20);
        }
        .ant-table-cell {
          &.ant-table-cell-row-hover {
            background-color: var(--color-neutral-30);
          }
          font-weight: var(--font-weight-regular);
          font-size: 12px;
          &.ant-table-column-sort {
            background: inherit;
          }
        }
      }
    }
    .ant-table-footer {
      text-align: right;
      background-color: var(--color-neutral-0);
      font-size: 10px;
      padding: 0px;
      color: var(--color-neutral-100);
    }
  }
}

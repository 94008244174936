.pready-password-recovery {
  height: 100vh;
  .password-recovery-sider {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--color-neutral-0);
    background-color: var(--color-neutral-100);
    padding: 67px 56px;
    .password-recovery-logo {
      width: 135px;
      position: absolute;
      top: 56px;
    }
    .recovery-info-content {
      font-size: 47px;
      line-height: 70px;
    }
  }
  .password-recovery-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 65px;
    background-color: var(--light-color);
    .ant-space {
      width: 100%;
    }
    .pready-login-link {
      color: var(--color-neutral-100);
    }
    .pready-login-form-footer {
      width: 100%;
      display: inline-block;
      text-align: center;
    }
  }
}

.project-card,
.project-card-create {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 335px;
  height: 200px;
  padding: 18px;
  border-radius: 16px;
  cursor: pointer;
  transition: filter 250ms;
  &:hover {
    filter: brightness(80%);
  }
  &.project-card-dimmed {
    filter: saturate(0%);
  }
}

.project-card {
  .project-card-topbar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .project-card-title {
      font-size: 18px;
      font-weight: var(--font-weight-medium);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .project-card-menu {
      display: flex;
      align-items: center;
      margin-left: 18px;
      z-index: 99;
      .anticon {
        font-size: 18px;
      }
    }
  }
  .project-card-content {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    .project-card-content-description {
      width: 65%;
      font-weight: var(--font-weight-medium);
      color: var(--color-neutral-0);
      font-size: 18px;
      overflow: hidden;
      overflow-y: auto;
      overflow-wrap: break-word;
    }
    .project-card-content-badge {
      width: 30%;
    }
  }
}

.project-card-create {
  .project-card-create-description {
    text-align: center;
    font-size: 12px;
    padding-top: 12px;
  }
  .project-card-create-badge {
    height: 76px;
  }
}

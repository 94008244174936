.pready-register {
  height: 100vh;
  .pready-register-sider {
    color: var(--color-neutral-0);
    background-color: var(--color-neutral-100);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    .register-sider-content {
      background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 79.72%);
      flex: 1;
      padding: 67px 56px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  .pready-register-main {
    padding: 100px 65px;
    background-color: var(--light-color);
    display: flex;
    flex-direction: column;
    .pready-register-stepper {
      margin-top: 80px;
      width: 100%;
      .pready-register-stepper-next-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
}

.message-screen-layout {
  background-color: var(--color-neutral-20);
  height: 100vh;
  .message-screen-logo {
    height: 40px;
  }
  .message-screen-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

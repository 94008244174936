.title-extra {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0px;
  .title-header {
    margin: 0px;
  }
  .title-extra-extra {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.title-extra-top {
  align-items: flex-start;
  .title-extra-extra-top {
    align-items: flex-start;
  }
}

.nav-sider-menu {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  .aws-logo {
    width: 120px;
    align-self: center;
  }
  ul {
    font-size: 18px;
    font-weight: var(--font-weight-medium);
    list-style-type: none;
    padding: 0;
    li {
      .li-nav-link {
        display: block;
        color: var(--color-neutral-50);
        padding: 30px 22px;
        border-left: 6px solid transparent;
        &:hover {
          background-color: var(--color-neutral-20);
        }
        &.active-nav-link {
          color: var(--color-neutral-100);
          border-left-color: black;
          background: linear-gradient(
            90deg,
            rgba(0, 0, 0, 0.15) 49.76%,
            rgba(0, 0, 0, 0) 109.9%
          );
        }
        .li-nav-content {
          display: flex;
          align-items: center;
          font-size: 18px;
          :first-child {
            margin-right: 8px;
          }
        }
      }
    }
  }
}

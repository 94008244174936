.user-plan-pill {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-neutral-100);
  border-radius: 16px;
  color: var(--color-neutral-0);
  padding: 10px 20px;
  min-height: 96px;
  .user-plan-tier {
    font-weight: var(--font-weight-medium);
    font-size: 24px;
  }
  .user-plan-total {
    text-align: end;
  }
}

.card-options-list {
  .card-options-item {
    text-align: center;
    background-color: var(--color-neutral-20);
    padding: 12px;
    border-radius: 8px;
    cursor: pointer;
    border: 2px solid var(--color-neutral-20);
    &.card-options-item-selected {
      background-color: var(--color-neutral-100);
      color: var(--color-neutral-0);
    }
  }
}

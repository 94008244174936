.app-layout {
  min-height: 100vh;
  .app-layout-sider {
    padding-top: 3%;
    padding-bottom: 3%;
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
      .app-layout-sider-logo {
        margin: 0px 44px 55px;
        width: 135px;
      }
    }
  }
  .app-layout-container {
    margin-left: 223px;
    padding: 2% 2% 3%;
  }
}

.project-filters {
  padding: 16px 2%;
  .ant-carousel > .project-filters-carousel {
    .project-filters-arrow {
      color: var(--color-neutral-100);
      font-size: 24px;
    }
    .slick-list {
      padding: 5px;
      padding-left: 3%;
      .slick-slide {
        .project-filters-card {
          display: flex !important;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: relative;
          height: 180px !important;
          width: 180px !important;
          background-color: var(--color-neutral-0);
          border-radius: 16px;
          padding: 10px;
          text-align: center;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

          &:hover {
            cursor: pointer;
          }
          &.selected {
            color: var(--color-neutral-0);
            background-color: var(--color-neutral-100);
          }
          .project-filters-card-title {
            font-size: 14px;
          }
          .project-filters-card-subtitle {
            position: absolute;
            bottom: 10px;
            font-size: 12px;
            padding-top: 11px;
            border-top: 1px solid var(--primary-color-50);
          }
        }
      }
    }
  }
}

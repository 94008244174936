.plan-features-container {
  background-color: var(--color-neutral-0);
  border-radius: 16px;
  color: var(--color-neutral-0);
  padding: 10px 20px;
  .plan-features-title {
    text-align: center;
  }
  .plan-features-price {
    display: block;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }
  .plan-features-description {
    display: block;
    text-align: center;
    font-size: 12px;
    color: var(--color-neutral-80);
    margin-bottom: 16px;
  }
  .plan-features-signup {
    text-align: center;
    margin: 8px 0 12px 0;
  }
  .plan-features-list {
    height: 35vh;
    overflow-y: auto;
  }
}

.create-accionable-modal {
  .create-accionable-modal-ok-btn {
    padding: 0 100px;
  }
  .create-accionable-modal-cancel-btn {
    display: none;
  }
}
.create-accionable {
  background-color: white;
  padding: 0 0;
  .create-accionable-column {
    height: 450px;
    overflow: auto;
    &.with-divider {
      border-right: 3px solid var(--color-neutral-30);
    }
  }
  .accionable-option {
    text-align: center;
    background-color: var(--color-neutral-20);
    padding: 12px;
    border-radius: 8px;
    cursor: pointer;
    border: 2px solid var(--color-neutral-20);
    &.accionable-option-selected {
      background-color: var(--color-neutral-100);
      color: var(--color-neutral-0);
    }
  }
  .accionable-date {
    padding: 8px 32px;
    border: 1px solid var(--color-neutral-30);
    border-radius: 16px;
    width: 300px;
    .accionable-date-time {
      width: 100%;
      border-radius: 8px;
      input {
        font-weight: var(--font-weight-medium);
        padding: 2px 0px;
        cursor: pointer;
        text-align: center;
      }
    }
  }
  .create-accionable-text {
    font-size: 24px;
    font-weight: var(--font-weight-medium);
    color: var(--color-neutral-100);
    .create-accionable-text-highlight {
      color: var(--primary-color-50);
      margin: 0px 8px;
    }
  }
}

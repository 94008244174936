.new-project-step-diagnostic-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.new-project-diagnostic-list {
  .new-project-diagnostic-card {
    height: 226px;
    .ant-card-body {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .card-title {
        margin: 0;
        font-size: 18px;
        font-weight: var(--font-weight-medium);
      }
      .card-result {
        align-self: flex-end;
        font-size: 16px;
        font-weight: var(--font-weight-medium);
      }
    }
  }
}

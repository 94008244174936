.userinfo-widget-icon {
  height: 48px;
  color: var(--color-neutral-100);
  &.dropdown {
    cursor: pointer;
  }
}

.userinfo-widget-content {
  width: 350px;
  color: var(--color-neutral-0);
  .userinfo-widget-content-title {
    font-size: 16px;
    margin-bottom: 12px;
    display: inline-flex;
    align-items: center;
    gap: 4px;
  }
  .plan-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    .plan-info-name {
      font-size: 14px;
      flex-grow: 1;
    }
    .plan-info-upgrade {
      color: var(--primary-color-50);
      font-size: 12px;
    }
  }
  .plan-limits {
    .plan-limits-title {
      font-size: 12px;
      margin-bottom: 8px;
    }
    .plan-limits-meter {
      .plan-limits-text {
        font-size: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: -5px;
      }
    }
  }
}

.userinfo-widget-logout-container {
  margin-top: 20px;
  border-top: 1px solid var(--color-neutral-0);
  .userinfo-widget-logout.ant-btn {
    font-size: 14px;
    color: var(--color-neutral-0);
    display: inline-flex;
    align-items: center;
    gap: 4px;
    margin-top: 5px;
    &:hover,
    &:active,
    &:focus-visible {
      outline: none;
      color: var(--color-neutral-0);
    }
  }
}

.notification-widget-icon-container {
  padding: 8px;
  border: 1px solid var(--color-neutral-100);
  border-radius: 50%;
  height: 48px;
  cursor: pointer;
  .notification-widget-icon {
    width: 30px;
    color: var(--color-neutral-100);
  }
}

.notification-widget-content {
  width: 350px;
  .notification-widget-content-title {
    font-size: 16px;
    font-weight: var(--font-weight-medium);
    color: var(--primary-color-50);
    margin-bottom: 12px;
  }
  .notification-widget-content-empty {
    font-size: 12px;
    margin-bottom: 4px;
    color: var(--color-neutral-0);
  }
}

.notification-widget-notification {
  display: flex;
  flex-direction: row;
  padding: 8px 0;
  border-bottom: 1px solid var(--color-neutral-30);
  .notification-widget-notification-text {
    font-size: 12px;
    color: var(--color-neutral-0);
    padding-right: 16px;
    flex-grow: 1;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .notification-widget-notification-date {
    font-size: 10px;
    color: var(--color-neutral-30);
    text-align: right;
    white-space: nowrap;
  }
}

.notification-widget-viewAll-container {
  margin-top: 12px;
  text-align: right;
  .notification-widget-viewAll.ant-btn {
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    gap: 4px;
    color: var(--primary-color-50);
    &:hover,
    &:active,
    &:focus-visible {
      outline: none;
      color: var(--primary-color-50);
    }
  }
}
